import * as React from 'react';

export const Resolution720IconOutline: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M5.997 22.5a.75.75 0 0 1 0-1.5h1.615l.5-3H2.247a2.252 2.252 0 0 1-2.25-2.25v-12a2.252 2.252 0 0 1 2.25-2.25h19.5a2.252 2.252 0 0 1 2.25 2.25v12a2.252 2.252 0 0 1-2.25 2.25h-5.865l.5 3h1.615a.75.75 0 0 1 0 1.5h-12Zm8.865-1.5-.5-3h-4.73l-.5 3h5.73ZM2.247 3a.75.75 0 0 0-.75.75v12c0 .414.336.75.75.75h19.5a.75.75 0 0 0 .75-.75v-12a.75.75 0 0 0-.75-.75h-19.5Zm12.162 4.786c0-.579.167-1.037.5-1.375.334-.337.849-.506 1.546-.506.697 0 1.212.169 1.546.506.333.338.5.796.5 1.375v3.993c0 .58-.167 1.038-.5 1.375-.334.338-.849.506-1.546.506-.697 0-1.212-.168-1.546-.506-.333-.337-.5-.795-.5-1.375V7.786ZM5.29 6.015h3.751v1.056l-.869 2.662a8.686 8.686 0 0 0-.44 2.695v1.122H6.39v-1.221c0-.975.154-1.906.462-2.794l.726-2.299H5.29V6.015Zm5.984-.055c1.481 0 2.222.58 2.222 1.738v.649c0 .719-.279 1.276-.836 1.672l-.935.748c-.308.242-.506.435-.594.578-.088.143-.132.35-.132.621v.407h2.442v1.177H9.657v-1.716c0-.432.086-.788.258-1.067a2.74 2.74 0 0 1 .754-.77l1.023-.825c.183-.139.308-.28.374-.423.066-.143.099-.347.099-.611v-.297c0-.212-.064-.376-.193-.489-.128-.114-.414-.171-.858-.171a3.35 3.35 0 0 0-1.314.275V6.18c.44-.146.931-.22 1.474-.22Zm5.907 1.87c0-.242-.053-.425-.16-.55-.106-.124-.295-.187-.566-.187-.271 0-.46.063-.567.187-.106.125-.159.308-.159.55v3.905c0 .242.053.426.159.55.107.125.296.187.567.187.271 0 .46-.062.566-.187.107-.124.16-.308.16-.55V7.83Z"
      />
    </svg>
);
