import * as React from 'react';

export const FullScreenIconOutline: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M3.756,22.503c-1.241,0-2.25-1.009-2.25-2.25v-16.5c0-1.241,1.009-2.25,2.25-2.25H20.26c1.241,0,2.25,1.009,2.25,2.25v16.5 c0,1.241-1.009,2.25-2.25,2.25H3.756z M3.756,3.003c-0.414,0-0.75,0.336-0.75,0.75v16.5c0,0.414,0.336,0.75,0.75,0.75H20.26 c0.414,0,0.75-0.336,0.75-0.75v-16.5c0-0.414-0.336-0.75-0.75-0.75H3.756z"
    />
    <path
      fill="currentColor"
      d="M15.008,19.503c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h1.939l-4.939-4.939l-4.939,4.939h1.939 c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-3.75c-0.088,0-0.175-0.016-0.26-0.047c-0.008-0.003-0.015-0.005-0.022-0.008 c-0.187-0.077-0.336-0.226-0.411-0.41c-0.003-0.007-0.006-0.015-0.009-0.023c-0.031-0.081-0.048-0.171-0.048-0.262v-3.75 c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v1.939l4.939-4.939L6.008,7.063v1.939c0,0.414-0.336,0.75-0.75,0.75 s-0.75-0.336-0.75-0.75v-3.75c0-0.089,0.016-0.176,0.048-0.261C4.558,4.985,4.56,4.978,4.563,4.971 C4.64,4.784,4.789,4.635,4.972,4.56C4.98,4.556,4.989,4.553,4.997,4.55c0.086-0.032,0.172-0.047,0.26-0.047h3.75 c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H7.068l4.939,4.939l4.939-4.939h-1.939c-0.414,0-0.75-0.336-0.75-0.75 s0.336-0.75,0.75-0.75h3.75c0.089,0,0.176,0.016,0.261,0.048c0.011,0.004,0.022,0.008,0.032,0.012 c0.176,0.072,0.324,0.221,0.4,0.404c0.003,0.008,0.007,0.017,0.01,0.026c0.031,0.081,0.047,0.17,0.047,0.259v3.75 c0,0.414-0.336,0.75-0.75,0.75s-0.75-0.336-0.75-0.75V7.063l-4.939,4.939l4.939,4.939v-1.939c0-0.414,0.336-0.75,0.75-0.75 s0.75,0.336,0.75,0.75v3.75c0,0.087-0.016,0.174-0.046,0.258c-0.002,0.006-0.004,0.011-0.006,0.017 c-0.08,0.195-0.229,0.343-0.412,0.419c-0.008,0.003-0.017,0.007-0.026,0.01c-0.081,0.031-0.17,0.047-0.259,0.047H15.008z"
    />
  </svg>
);
