import * as React from 'react';

export const ExitFullScreenIconOutline: React.FC<
React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M3.756,22.503c-1.241,0-2.25-1.009-2.25-2.25v-16.5c0-1.241,1.009-2.25,2.25-2.25H20.26c1.241,0,2.25,1.009,2.25,2.25v16.5 c0,1.241-1.009,2.25-2.25,2.25H3.756z M3.756,3.003c-0.414,0-0.75,0.336-0.75,0.75v16.5c0,0.414,0.336,0.75,0.75,0.75H20.26 c0.414,0,0.75-0.336,0.75-0.75v-16.5c0-0.414-0.336-0.75-0.75-0.75H3.756z"
    />
    <path
      fill="currentColor"
      d="M6.756 10.503c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.189l-3.22-3.22c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53c.141-.142.33-.22.53-.22s.389.078.53.22l3.22 3.22V6.753c0-.414.336-.75.75-.75s.75.336.75.75v3c0 .089-.016.176-.048.261-.002.007-.005.014-.007.02-.077.187-.226.336-.41.412-.008.003-.016.006-.025.009-.086.032-.172.047-.26.047H6.756zM14.26 10.503c-.089 0-.176-.016-.261-.048-.011-.004-.022-.008-.032-.012-.176-.072-.324-.221-.4-.404-.003-.008-.007-.017-.01-.026-.031-.082-.047-.17-.047-.259v-3c0-.414.336-.75.75-.75s.75.336.75.75v1.189l3.22-3.22c.142-.142.33-.22.53-.22s.389.078.53.22c.142.141.22.33.22.53s-.078.389-.22.53l-3.22 3.22h1.189c.414 0 .75.336.75.75s-.336.75-.75.75H14.26zM5.256 19.503c-.2 0-.389-.078-.53-.22-.142-.142-.22-.33-.22-.53s.078-.389.22-.53l3.22-3.22H6.756c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h3c.088 0 .175.016.26.047.008.003.015.005.022.008.188.077.336.226.412.41.003.007.006.015.009.023.031.081.048.171.048.262v3c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.189l-3.22 3.22C5.645 19.425 5.456 19.503 5.256 19.503zM18.76 19.503c-.2 0-.389-.078-.53-.22l-3.22-3.22v1.189c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3c0-.087.016-.174.046-.258.002-.006.004-.011.006-.017.08-.195.229-.343.412-.419.008-.003.017-.007.026-.01.082-.031.17-.047.259-.047h3c.414 0 .75.336.75.75s-.336.75-.75.75H16.07l3.22 3.22c.142.142.22.33.22.53s-.078.389-.22.53S18.96 19.503 18.76 19.503z"
    />
  </svg>
);
