import * as React from 'react';

export const VolumeLevel0IconOutline: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12.749 20.245c-.479 0-.936-.149-1.322-.43l-6.394-4.069H2.251c-1.241 0-2.25-1.009-2.25-2.25v-3c0-1.241 1.009-2.25 2.25-2.25h2.781l6.394-4.069c.389-.283.846-.433 1.323-.433.346 0 .695.083 1.008.239.768.384 1.244 1.155 1.244 2.013v12c0 .483-.16.963-.45 1.35C14.128 19.909 13.455 20.245 12.749 20.245zM12.254 18.563c.013.009.029.019.043.03.134.101.289.153.452.153.24 0 .458-.109.601-.3.097-.129.15-.289.15-.45v-12c0-.286-.159-.543-.414-.671-.106-.053-.22-.08-.336-.08-.162 0-.317.052-.449.151-.015.012-.031.022-.046.032l-6.254 3.98v5.177L12.254 18.563zM2.251 9.746c-.414 0-.75.336-.75.75v3c0 .414.336.75.75.75h2.25v-4.5H2.251zM23.249 15.371c-.2 0-.389-.078-.53-.22l-2.095-2.095-2.095 2.095c-.142.142-.33.22-.53.22s-.389-.078-.53-.22c-.292-.292-.292-.768 0-1.061l2.095-2.095-2.095-2.095c-.142-.141-.22-.33-.22-.53s.078-.389.22-.53.33-.22.53-.22.389.078.53.22l2.095 2.095 2.095-2.095c.142-.142.33-.22.53-.22s.389.078.53.22c.142.141.22.33.22.53s-.078.389-.22.53l-2.095 2.094 2.095 2.095c.292.292.292.768 0 1.061C23.638 15.293 23.449 15.371 23.249 15.371z"
      />
    </svg>
);
