import * as React from 'react';

export const HomeIconOutline: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
        fill="currentColor"
        d="M14.253,22.945c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414-0.336-0.75-0.75-0.75h-1.5c-0.414,0-0.75,0.336-0.75,0.75v6 c0,0.414-0.336,0.75-0.75,0.75h-6c-0.414,0-0.75-0.336-0.75-0.75v-8.25c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v7.5h4.5 v-5.25c0-1.241,1.009-2.25,2.25-2.25h1.5c1.241,0,2.25,1.009,2.25,2.25v5.25h4.5v-7.5c0-0.414,0.336-0.75,0.75-0.75 s0.75,0.336,0.75,0.75v8.25c0,0.414-0.336,0.75-0.75,0.75H14.253z"
    />
    <path
        fill="currentColor"
        d="M0.753,13.195c-0.2,0-0.389-0.078-0.53-0.22c-0.292-0.292-0.292-0.768,0-1.061L10.412,1.726 c0.424-0.425,0.989-0.658,1.591-0.658c0.601,0,1.166,0.234,1.591,0.658l10.19,10.19c0.292,0.292,0.292,0.768,0,1.061 c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22L12.534,2.786c-0.142-0.141-0.33-0.219-0.531-0.219 s-0.389,0.078-0.53,0.219L1.283,12.975C1.142,13.117,0.953,13.195,0.753,13.195z"
    />
  </svg>
);
