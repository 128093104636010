import { styled } from 'baseui';

export const ModuleContainer = styled('div', {
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
});
