import * as React from 'react';

export const Resolution1080IconOutline: React.FC<
React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5.997 22.5a.75.75 0 0 1 0-1.5h1.615l.5-3H2.247a2.252 2.252 0 0 1-2.25-2.25v-12a2.252 2.252 0 0 1 2.25-2.25h19.5a2.252 2.252 0 0 1 2.25 2.25v12a2.252 2.252 0 0 1-2.25 2.25h-5.865l.5 3h1.615a.75.75 0 0 1 0 1.5h-12Zm8.865-1.5-.5-3h-4.73l-.5 3h5.73ZM2.247 3a.75.75 0 0 0-.75.75v12c0 .414.336.75.75.75h19.5a.75.75 0 0 0 .75-.75v-12a.75.75 0 0 0-.75-.75h-19.5Zm4.39 4.786c0-.579.167-1.037.501-1.375.334-.337.849-.506 1.545-.506.697 0 1.212.169 1.546.506.334.338.5.796.5 1.375v3.993c0 .58-.166 1.038-.5 1.375-.334.338-.849.506-1.546.506-.696 0-1.211-.168-1.545-.506-.334-.337-.501-.795-.501-1.375V7.786Zm10.208 0c0-.579.167-1.037.501-1.375.334-.337.849-.506 1.546-.506.696 0 1.211.169 1.545.506.334.338.501.796.501 1.375v3.993c0 .58-.167 1.038-.501 1.375-.334.338-.849.506-1.545.506-.697 0-1.212-.168-1.546-.506-.334-.337-.501-.795-.501-1.375V7.786Zm-.979-.044v.759c0 .609-.201 1.042-.605 1.298.411.257.616.69.616 1.298v.726c0 .572-.174 1.021-.522 1.348-.348.326-.873.489-1.573.489s-1.223-.163-1.568-.489c-.344-.327-.517-.776-.517-1.348v-.726c0-.601.202-1.034.605-1.298-.396-.256-.594-.689-.594-1.298v-.759c0-.572.171-1.021.512-1.347.341-.327.862-.49 1.562-.49.7 0 1.223.163 1.567.49.345.326.517.775.517 1.347ZM5.559 6.015v7.535H4.217V7.566l-1.265.924V7.027l1.375-1.012h1.232Zm9.009 5.786v-.748c0-.491-.26-.737-.78-.737-.521 0-.782.246-.782.737v.748c0 .477.261.715.782.715.52 0 .78-.238.78-.715ZM9.409 7.83c0-.242-.053-.425-.159-.55-.106-.124-.295-.187-.567-.187-.271 0-.46.063-.566.187-.106.125-.16.308-.16.55v3.905c0 .242.054.426.16.55.106.125.295.187.566.187.272 0 .461-.062.567-.187.106-.124.159-.308.159-.55V7.83Zm10.209 0c0-.242-.054-.425-.16-.55-.106-.124-.295-.187-.566-.187-.272 0-.461.063-.567.187-.106.125-.16.308-.16.55v3.905c0 .242.054.426.16.55.106.125.295.187.567.187.271 0 .46-.062.566-.187.106-.124.16-.308.16-.55V7.83Zm-5.061-.066c0-.476-.256-.715-.769-.715-.514 0-.771.239-.771.715v.77c0 .499.257.748.771.748.513 0 .769-.249.769-.748v-.77Z"
    />
  </svg>
);
