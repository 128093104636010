import * as React from 'react';

export const ExitMiniModeIconOutline: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M9.01 24.003c.827 0 1.5-.673 1.5-1.5v-1.5c0-.827-.673-1.5-1.5-1.5h-7.5c-.827 0-1.5.673-1.5 1.5v1.5c0 .827.673 1.5 1.5 1.5h7.5Zm0-1.5h-7.5v-1.5h7.5v1.5ZM11.064 9.38a.75.75 0 0 0 0 1.5h2.689l-5.846 5.845a.743.743 0 0 0-.22.53c0 .2.078.389.22.53.141.142.33.22.531.22.2 0 .389-.078.53-.22l5.845-5.845v2.689a.75.75 0 0 0 1.5 0v-4.5a.723.723 0 0 0-.048-.258c-.001-.006-.004-.011-.006-.017a.76.76 0 0 0-.412-.419l-.026-.01a.733.733 0 0 0-.259-.047h-4.498v.002Zm10.696 5.623a2.252 2.252 0 0 0 2.25-2.25v-10.5a2.252 2.252 0 0 0-2.25-2.25H8.26a2.252 2.252 0 0 0-2.25 2.25v10.5a.75.75 0 0 0 1.5 0v-6.75h15v6.75a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 0-.75.75c0 .414 5.25.75 5.25.75ZM7.51 4.503v-2.25a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 .75.75v2.25h-15Z"
      />
    </svg>
);
