import * as React from 'react';

export const PlayIconOutline: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M3.839,22.995c-1.24,0-2.25-1.01-2.25-2.25V3.254c0-0.37,0.092-0.738,0.267-1.064C2.14,1.661,2.613,1.274,3.189,1.1 c0.213-0.064,0.432-0.097,0.652-0.097c0.367,0,0.734,0.093,1.061,0.268l16.323,8.745c0.392,0.21,0.71,0.528,0.92,0.92 c0.284,0.53,0.344,1.138,0.171,1.714c-0.174,0.575-0.561,1.049-1.091,1.333L4.902,22.728C4.576,22.902,4.208,22.995,3.839,22.995 C3.839,22.995,3.839,22.995,3.839,22.995z M3.84,2.504c-0.073,0-0.146,0.011-0.218,0.033C3.43,2.594,3.273,2.723,3.178,2.9 C3.12,3.008,3.089,3.131,3.089,3.254v17.49c0,0.414,0.337,0.75,0.75,0.75c0.123,0,0.246-0.031,0.354-0.089l16.324-8.745 c0.177-0.094,0.305-0.252,0.363-0.444c0.058-0.192,0.038-0.395-0.057-0.572c-0.07-0.131-0.176-0.237-0.306-0.307L4.194,2.594 C4.083,2.534,3.964,2.504,3.84,2.504z"
    />
  </svg>
);
