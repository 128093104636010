import * as React from 'react';

export const VolumeLevel2IconOutline: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M14.322 20.247c-.477 0-.933-.149-1.321-.431l-6.394-4.069H3.826c-1.241 0-2.25-1.009-2.25-2.25v-3c0-1.241 1.009-2.25 2.25-2.25h2.782l6.395-4.069c.389-.284.846-.433 1.323-.433.346 0 .695.083 1.008.239.767.385 1.243 1.156 1.242 2.014v11.999c0 .483-.16.963-.45 1.35-.361.481-.887.792-1.482.877C14.537 20.24 14.429 20.247 14.322 20.247zM13.829 18.564c.014.009.028.019.041.028.137.103.292.155.455.155.036 0 .072-.002.108-.008.198-.028.373-.132.493-.292.097-.129.15-.289.15-.45v-12c0-.286-.158-.543-.414-.671-.106-.053-.219-.08-.336-.08-.162 0-.317.052-.448.151C13.862 5.409 13.846 5.42 13.83 5.43L7.576 9.409v5.176L13.829 18.564zM3.826 9.747c-.414 0-.75.336-.75.75v3c0 .414.336.75.75.75h2.25v-4.5H3.826zM20.326 15.747c-.232 0-.447-.104-.59-.287-.255-.325-.198-.798.127-1.053 1.198-.939 1.408-2.676.469-3.874-.137-.175-.295-.333-.469-.469-.325-.255-.382-.727-.127-1.053.143-.183.358-.287.591-.287.167 0 .331.057.462.159.27.212.513.455.724.724.701.894 1.011 2.009.875 3.14-.137 1.131-.705 2.139-1.598 2.84C20.655 15.692 20.495 15.747 20.326 15.747z"
      />
    </svg>
);
